import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import Header from "./Header";
import PatientDataMenu from "../PatientData/PatientDataMenu";
import PatientActionMenu from "../PatientData/PatientActionMenu";
import {Container} from "react-bootstrap";
import RootPanel from "../Panels/RootPanel";
import {createLoadingSelector, getAuthData} from "../../utils/selectors";
import {loadPatientService} from "../../actions/patients.actions";
import {getPatientServiceIdParam} from "../../utils/helpers";
import {useLocation} from "react-router-dom";
import moment from "moment-timezone";

const PatientDataLayout = (props) => {
    const {loadPatientService, canAccessActionMenu} = props;

    const location = useLocation();
    const patientServiceId = getPatientServiceIdParam(location);

    useEffect(() => {
        if (patientServiceId) {
            loadPatientService(patientServiceId);
        }
    }, [patientServiceId]);
    moment.tz.setDefault("UTC");

    return (
        <>
            <Header {...props} />

            <PatientDataMenu {...props} patientServiceId={patientServiceId} />

            {canAccessActionMenu &&
                <PatientActionMenu {...props} />
            }

            <main>
                <Container fluid>
                    {props.children}
                </Container>
                <RootPanel {...props} />
            </main>
        </>
    );
}

const patientServiceLoader = createLoadingSelector(['LOAD_PATIENT_SERVICE']);

const mapStateToProps = (state, props) => {
    const authorities = getAuthData(state).authorities || [];
    const canAccessActionMenu = !authorities.includes("GUEST");

    const loadingPatientService = patientServiceLoader(state);

    return {
        canAccessActionMenu,
        loadingPatientService,
    };
};

const mapDispatchToProps = {
    loadPatientService,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDataLayout);
